export const dictionaryRoutes: { [key: string]: string } = {
  Grupossub1: "Sub-Grupo (Nível 1)",
  Grupossub2: "Sub-Grupo (Nível 2)",
  Localizacoes: "Localizações",
  Servicos: "Serviços",
  Seratividades: "Atividades de Serviços",
  Pagtoforma: "Forma de Pagamento",
  Pagtoprazo: "Prazo de Pagamento",
  Natoperacao: "Natureza da Operação",
  Usuarios: "Usuários",
  Municipios: "Municípios",
  Paises: "Países",
  Relatorios: "Relatórios",
  Cfop: "CFOP",
  Config: "Configuração",
  Plano: "Plano Contratado",
  usuariosgrupospermissoes: "Grupo de Permissões",
  relatorio: "Relatório",
  depositos: "Depósitos",
  Emitentes: "Emitentes",
  Vendedores: "Vendedores",
  Funcionarios: "Funcionários",
  Transportadoras: "Transportadoras",
  Clientes: "Clientes",
  fornecedores: "Fornecedores",
  unidades: "Unidades",
  fabricantes: "Fabricantes",
  grupos: "Grupos",
  gruposub1: "Sub-Grupos (Nível 1)",
  gruposub2: "Sub-Grupos (Nível 2)",
  Produtos: "Produtos",
  ncm: "NCM",
  vendas: "Vendas",
  NFe: "NF-e",
  Nfce: "NFC-e"
};
